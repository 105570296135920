<template>
<div>
    <div class="col-md-12" v-if="!showmastermodal">
        <div class="col-md-12">
            <div class="row" style="margin-top: 20px">
                <div class="col-md-6">
                    <h3 class="page-header">
                        <i class="fa fa fa-users animated bounceInDown show-info"></i>
                        Master
                    </h3>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6" style="padding-top: 18px">
                            <button @click="refresh()" class="btn btn-primary">
                                <i class="fa fa-refresh"></i> Refresh
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 cardbox">
            <div class="col-md-12 m-top-10 m-bottom" style="text-align:center;">
                <select v-model="ajaxtype">
                    <option v-for="(item) in gettypes(0)" :value="item.labelid" :key="item.id">{{ item.label }}</option>
                </select>
                <button @click="addnew" class="btn btn-primary"> <span> Add New </span></button>
            </div>
            <div class="col-md-12 ">
                <table class="table " style="font-size:12px;">
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Label</th>
                            <th>LabelId</th>
                            <th>Sequence</th>
                            <th>Value</th>
                            <th>Value2</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr v-for="(item, index) in gettypes1" v-bind:key="item.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.label }}</td>
                        <td>{{ item.labelid }}</td>
                        <td>{{ item.sequence }}</td>
                        <td>{{ item.value1 }}</td>
                        <td>{{ item.value2 }}</td>
                        <td><button @click="edit(item)" class="btn btn-primary btn-sm">Edit</button></td>
                    </tr>
                </table>
            </div>

        </div>
    </div>
    <div class="col-md-12">
        <div v-if="showmastermodal" style="background-color:white;">
            <form @submit.prevent="onSubmit" @keydown="form.errors.clear($event.target.name)">
                <div class="">
                    <h2>Add New </h2>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label> Type</label>
                    </div>
                    <div class="col-md-8">
                        <input type="number" v-model="form.type" readonly>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label> Label</label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" v-model="form.label">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Label Id</label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" v-model="form.labelid">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Sequence</label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" v-model="form.sequence">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Value</label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" v-model="form.value1">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Value2</label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" v-model="form.value2">
                    </div>
                </div>
                <button class="btn btn-primary btn-sm">Submit</button>
                <button type="button" class="btn btn-primary btn-sm" @click="cancel()">Cancel</button>
            </form>

        </div>
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
export default {
    mixins: [Constants],
    data() {
        return {
            list: null,
            ajaxtype: 0,
            form: new window.Form({
                id: 0, type: 0, label: '', labelid: '', sequence: 0, value1: '', value2: ''
            }),
            showmastermodal: false
        }
    },
    mounted() {
        this.refresh();

    },
    computed: {
        gettypes1() {
            return this.companymaster.filter(block => block.type == this.ajaxtype)
        },
        ...mapGetters([
            'companymaster'
        ]),
    },
    methods: {
        gettypes(type) {
            return this.companymaster.filter(block => block.type == type)
        },
        edit(item) {
            //this.form = new Form(item);
            this.form.label = item.label
            this.form.labelid = item.labelid
            this.form.type = item.type
            this.form.id = item.id
            this.showmastermodal = true
            //this.$modal.show("mastermodal");
        },
        onSubmit() {
            this.$http.post('api/companymaster/createupdate', this.form)
                .then(response => this.processResponse(response))
                .catch(error => this.showError(error));
        },
        addnew() {
            this.form = new window.Form({ id: 0, type: 0, label: '', labelid: 0, sequence: 0, value1: '', value2: '' });
            this.form.type = this.ajaxtype;
            this.showmastermodal = true
        },
        processResponse(data) {
            if (this.list == null) {
                this.list = {};
            }
            this.list = data;
            this.showmastermodal = false
        },
        cancel() {
            this.showmastermodal = false
        },
        refresh() {
            this.$store.dispatch('fetchcompanymaster')
            let param = { type: 0 };
            this.ajaxtype = 0;
            this.$http.post('api/companymaster/getvalues', param)
                .then(response => this.processResponse(response.data))
                .catch(error => this.showError(error));
        },
        showError() {
            this.$store.commit('assignloadingstatus', 0)
            alert("Something went wrong, please try again.")
        },
    }
} 
</script>